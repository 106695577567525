<template>
  <div class="main">
    <div class="detail">
      <div class="left">
        <div class="name">水印打卡拍照</div>
        <div class="tips">
          水印打卡拍照是一款拍照时自动显示时间、地点的软件，轻松记录你的生活，同时也便于进行工作汇报，图片信息整理等。
        </div>
        <div>
          <a href="https://139.196.224.113:35942/down/Hc066hkspVoe.apk">
            <div class="btn-wrap">下载</div>
          </a>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/image/detail1.jpg" style="width: 40%; height: 80%" />
        <img src="@/assets/image/detail2.jpg" style="width: 40%; height: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'detailInfo',
  props: {
    msg: String,
  },
  methods: {
    download() {},
  },
};
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .name {
      font-size: 40px;
      font-weight: bold;
      color: white;
    }
    .tips {
      color: white;
      line-height: 2;
      font-size: 20px;
      padding: 0 10%;
    }
    .btn-wrap {
      width: 200px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      border-radius: 20px;
      border: 1px solid #288cff;
      background-color: #288cff;
      color: white;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: #288ccf;
      }
    }
    a {
      text-decoration: none;
    }
  }
  .right {
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}
</style>
