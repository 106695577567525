import Vue from 'vue'
import App from './App.vue'
import vrouter from '@/views/router'
import '@/assets/less/reset.less'

Vue.config.productionTip = false
const router = vrouter()
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
