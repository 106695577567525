import Vue from 'vue'
import VueRouter from 'vue-router'

// 注册路由
Vue.use(VueRouter)

const files = require.context('@/views', true, /\.vue$/)
const conponentsMap = {}
files.keys().forEach(key => {
  conponentsMap[key.slice(1, -4)] = files(key).default
})
export default function () {
  const routes = [
    {
      path: '/',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/detail',
      component: () => import('@/views/detailInfo.vue')
    },
  ]

  var router = new VueRouter({
    mode: 'hash',
    routes
  })

  // 路由守卫
  router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next()
  })

  return router
}
