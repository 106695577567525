<template>
  <div>
    <div class="main">
      <div class="title">公司简介</div>
      <div class="desc">
        长沙英熊网络科技有限公司于2024年在湖南长沙成立，是一家专注于研究、开发和运营工具类APP的高新科技企业，与腾讯、快手、百度等一线互联网企业保持长期的深入合作。公司自成立以来，始终秉承“以人为本、诚信立业”的经营原则，以“品质为上，用心服务”为核心价值。希望通过我们专业的水平和不懈的努力，为大众提供更方便与高效的实用工具，帮助我们节省时间和精力，让我们能够更快捷地完成任务，便利生活。
      </div>
    </div>
    <div class="other">
      <div class="wrap">
        <div class="tip-content glass-container">
          <h1 class="main-title">旗下产品</h1>
          <h2 class="title">水印打卡拍照</h2>
          <img src="@/assets/image/logo.jpg" style="width: 100px" />
          <div class="text">
            应用介绍：水印打卡拍照是一款拍照时自动显示时间、地点的软件，轻松记录你的生活，同时也便于进行工作汇报，图片信息整理等。
          </div>
          <div class="btn-wrap" @click="goDetail">查看详情</div>
        </div>
      </div>
      <div class="wrap">
        <div class="tip-content glass-container">
          <h2 class="title">企业使命</h2>
          <div class="text">
            在数字时代，我们致力于为客户提供最前沿、最可靠的互联网服务体验；同时，我们为员工打造一个充满挑战与机遇的平台，让每个人都能在这里展现才华，实现自我价值，共同编织幸福璀璨的人生篇章。
          </div>
        </div>
        <div class="tip-content glass-container">
          <h2 class="title">企业愿景</h2>
          <div class="text">
            作为互联网行业的创新者，我们梦想成为用户心中最值得信赖的伙伴，不仅限于工具，而是覆盖更广泛的数字服务领域，引领行业向更加智能、高效的方向迈进。
          </div>
        </div>
        <div class="tip-content glass-container">
          <h2 class="title">企业价值观</h2>
          <div class="text">
            客户至上：将用户需求置于首位，持续优化产品与服务，确保每一次交互都超越期待。
            服务宗旨：我们承诺为客户提供高效、便捷、安全、个性化的互联网服务体验，通过智能化技术为客户提供最贴合需求的解决方案，不断提升客户满意度，让每一位用户都能感受到科技带来的美好改变。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeInfo',
  props: {
    msg: String,
  },
  methods: {
    goDetail() {
      this.$router.push({ path: '/detail' });
    },
  },
};
</script>

<style lang="less" scoped>
.other {
  padding: 30px;
  background-image: url('@/assets/image/img-2.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-color: white;
  .wrap {
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 20px;
  }
}

.tip-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  .main-title {
    font-size: 56px;
    text-shadow: 0px 0px 5px black, 1px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .title {
    font-size: 24px;
    text-shadow: 0px 0px 5px black, 1px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .text {
    font-size: 16px;
    line-height: 1.5;
    text-shadow: 0px 0px 5px black, 1px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .btn-wrap {
    width: 200px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    text-align: center;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}
.glass-container {
  flex: 1;
  min-height: 280px;
  color: white;
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 10px;
  backdrop-filter: blur(7px);
  background-color: rgba(4, 0, 255, 0.083);
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
  border: 1px rgba(255, 255, 255, 0.4) solid;
  border-bottom: 1px rgba(40, 40, 40, 0.35) solid;
  border-right: 1px rgba(40, 40, 40, 0.35) solid;
}
</style>
