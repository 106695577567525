<template>
  <div class="app">
    <div class="nav" @click="goHome">
      <strong>长沙英熊网络科技有限公司</strong>
    </div>
    <router-view />
    <footer class="footer">
      © 2024
      <a href="https://beian.miit.gov.cn" class="footer-link" target="_blank">
        湘ICP备2024080103号-2
      </a>
      长沙英熊网络科技有限公司
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    goHome() {
      console.log(`this.$router`, this.$router);
      if (this.$router.app._route.fullPath === '/') {
        return;
      }
      this.$router.replace({ path: '/' });
    },
  },
};
</script>

<style lang="less" >
.app {
  position: relative;
}
.nav {
  position: absolute;
  top: 0;
  color: white;
  padding: 30px 20% 0;
  font-size: 30px;
  z-index: 10;
  cursor: pointer;
}
.main {
  height: 100vh;
  background: rgba(40, 140, 255, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;

  .title {
    font-size: 56px;
    font-weight: bold;
    color: white;
  }
  .desc {
    padding: 30px 30% 0;
    color: white;
    line-height: 2;
    font-size: 20px;
  }
}
.main::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  background-image: url('@/assets/image/img-2.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.footer {
  width: 100%;
  background-color: black;
  background-color: #192b3f !important;
  padding: 70px 0 30px;
  text-align: center;
  color: #6c757d;
  .footer-link {
    cursor: pointer;
    color: #6c757d;
    font-size: 14px;
    &:hover {
      color: #288cff;
    }
  }
}
</style>
